import React, { useEffect, useRef, useState } from "react";
import { FaSpinner, FaUser } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { axiosServer, buildLink, saveTokenToCookie } from "../lib/server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PointLoader from "./pointLoader";
import { useDispatch, useSelector } from "react-redux";
import { RiLoader4Line } from "react-icons/ri";
import Popup from "reactjs-popup";
const Account = () => {
  const [openRegister, setOpenRegister] = useState(false);
  const [typeRegister, setTypeRegister] = useState("login");
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const phoneRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const [loadingRegistring, setLoadingRegistering] = useState(false);
  const [error, setError] = useState(null);
  const stateAccount = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("_____");
    if (stateAccount) {
      console.log(stateAccount.userInfo);
    }
  }, [stateAccount]);
  const login = async (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    try {
      setLoadingRegistering(true);
      const response = await axiosServer.post(buildLink("login"), {
        email,
        password,
      });
      setLoadingRegistering(false);
      saveTokenToCookie(response.data.token);
      getUser();
    } catch (err) {
      setLoadingRegistering(false);
      const error = err.response.data;
      // setError(Object.keys(error)[0])
      var errorMessage = "";
      console.log(error.error);

      if (err.status === 400) {
        errorMessage = error[Object.keys(error)[0]][0];
      } else {
        errorMessage = error.error;
      }
      toast(errorMessage, { type: "error" });
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  function getUser() {
    try {
      dispatch({ type: "SET_LOADING_ACCOUNT", payload: true });
      axiosServer.get(buildLink("user")).then((response) => {
        dispatch({ type: "SET_USER_INFO", payload: response.data.user });
      });
    } catch (e) {
      console.log(e);
    }
  }

  const signUp = async (e) => {
    e.preventDefault();

    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const phone = phoneRef.current.value;
    const name = firstNameRef.current.value;
    try {
      setLoadingRegistering(true);
      const response = await axiosServer.post(buildLink("register"), {
        email,
        password,
        name,
        password_confirmation: password,
      });
      if (response.data.success) {
        setOpenRegister(false);
        saveTokenToCookie(response.data.token);
        getUser();
      }
      setLoadingRegistering(false);
    } catch (err) {
      setLoadingRegistering(false);
      const error = err.response.data;
      // setError(Object.keys(error)[0])
      toast(error[Object.keys(error)[0]][0], { type: "error" });
    }
  };

  return (
    <>
      <ToastContainer className="z-[9999999]" />

      <button
        className=" my-auto flex  text-black hover:text-[#548742] transition-all flex-row gap-3  rounded-md p-2"
        onClick={() => setOpenRegister(true)}
      >
        <FaUser className="my-auto   " />

       

        {stateAccount.loading ? (
          <RiLoader4Line className=" my-auto font-bold animate-spin" />
        ) : stateAccount.userInfo && stateAccount.userInfo.id > 0 ? (
          <span>{stateAccount.userInfo.name}</span>
        ) : (
          <span>Login</span>
        )}
      </button>

      {openRegister && (
        <>
          <div className="fixed w-full h-full bg-black opacity-50 z-50 inset-0"></div>

          <div className="fixed w-[35%] h-fit max-md:w-[90%] bg-white mx-auto inset-0 my-auto z-50 rounded-lg">
            <div className=" w-full h-full relative ">
              <button
                className="absolute  right-2 top-2 "
                onClick={() => {
                  setOpenRegister(false);
                }}
              >
                <MdOutlineClose className=" text-3xl " />
              </button>
              <div className=" flex flex-col  gap-4 pt-12 pb-2">
                <h2 className=" text-xl font-semibold text-gray-700 text-center">
                  Enter Your Information{" "}
                </h2>

                <div className=" flex flex-row  w-fit  mx-auto border-4  rounded-full bg-gray-500 border-gray-500   justify-center gap-3 ">
                  <button
                    onClick={() => setTypeRegister("login")}
                    className={` ${
                      typeRegister == "login"
                        ? "bg-white   text-gray-500 "
                        : "bg-transparent text-white"
                    } transition-all text-center whitespace-nowrap rounded-full py-3 w-full px-5  text-sm `}
                  >
                    Login
                  </button>

                  <button
                    onClick={() => setTypeRegister("signup")}
                    className={` ${
                      typeRegister == "signup"
                        ? "bg-white   text-gray-500 "
                        : "bg-transparent text-white"
                    }  transition-all text-center py-3 whitespace-nowrap  rounded-full  w-full px-5  text-sm `}
                  >
                    Sign Up
                  </button>
                </div>

                {typeRegister == "login" && (
                  <form
                    onSubmit={login}
                    className="flex flex-col gap-3 py-4 px-2 md:px-6"
                  >
                    <input
                      ref={emailRef}
                      type="text"
                      className="rounded-md px-2 py-3 outline-none  bg-gray-100 border border-opacity-20 border-black"
                      placeholder="email"
                    />
                    <input
                      ref={passwordRef}
                      type="password"
                      className="rounded-md px-2 py-3 outline-none  border border-opacity-20 border-black bg-gray-100"
                      placeholder="Password"
                    />
                    <button className=" text-right text-[#8BC542] text-sm">
                      Forget Password
                    </button>

                    <button className=" w-full    py-3  bg-[#8BC542]  rounded-md text-white">
                      {loadingRegistring ? (
                        <div className="  w-24  py-1 relative mx-auto">
                          <PointLoader />
                        </div>
                      ) : (
                        "Log In"
                      )}
                    </button>
                  </form>
                )}

                {typeRegister == "signup" && (
                  <form
                    onSubmit={signUp}
                    className="flex flex-col gap-3 py-4 px-2 md:px-6"
                  >
                    <div className=" w-full flex flex-row gap-2 ">
                      <input
                        ref={firstNameRef}
                        type="text"
                        className="rounded-md px-2 py-3 w-full outline-none  bg-gray-100 border border-opacity-20 border-black"
                        placeholder="First Name"
                      />
                      <input
                        ref={lastNameRef}
                        type="text"
                        className="rounded-md px-2 py-3 w-full outline-none  border border-opacity-20 border-black bg-gray-100"
                        placeholder="Last Name"
                      />
                    </div>
                    <input
                      ref={emailRef}
                      type="email"
                      className="rounded-md px-2 py-3 w-full outline-none  border border-opacity-20 border-black bg-gray-100"
                      placeholder="Email : example@gmail.com"
                    />
                    <input
                      ref={phoneRef}
                      type="tel"
                      className="rounded-md px-2 py-3 w-full outline-none  border border-opacity-20 border-black bg-gray-100"
                      placeholder="Tel : 03 174 037"
                    />
                    <input
                      ref={passwordRef}
                      type="password"
                      className="rounded-md px-2 py-3 w-full outline-none  border border-opacity-20 border-black bg-gray-100"
                      placeholder="Password"
                    />

                    <button className=" w-full    py-3  bg-[#8BC542]  rounded-md text-white">
                      {loadingRegistring ? (
                        <div className="  w-24  py-1 relative mx-auto">
                          <PointLoader />
                        </div>
                      ) : (
                        "Sign Up"
                      )}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Account;
