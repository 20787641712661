import { combineReducers, createStore, applyMiddleware } from "redux";
import { thunk } from 'redux-thunk';

import { axiosServer, buildLink } from "../lib/server";

// Categories Reducer
const categoriesReducer = (
  state = { categories: [], loading: true },
  action
) => {
  switch (action.type) {
    case "SET_CATEGORIES":
      return { ...state, categories: action.payload, loading: false };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

// Cart Reducer (Synchronous)
const cartReducer = (state = {total_price:0, cartCount:0, lastItemAdded:0, addingItem:false, cartItems: [], loading: true, openCartSid: false }, action) => {
  switch (action.type) {
    case "SET_CART_ITEMS":
      return { ...state, cartItems: action.payload, loading: false };
  
    case "SET_CART_LOADING":
      return { ...state, loading: action.payload };
      case "SET_TOTAL_PRICE":
        return { ...state, total_price: action.payload };
      case "SET_CART_COUNT":
        return { ...state, cartCount: action.payload };
      case "SET_LAST_ITEM_ADDED":
        return { ...state, lastItemAdded: action.payload };
    case "SET_OPEN_CART_SIDE":
      return { ...state, openCartSid: action.payload };
    default:
      return state;
  }
};

const accountReducer = (state = {userInfo:null,loading:false }, action) => {
  switch (action.type) {
    case "SET_USER_INFO":
      return { ...state, userInfo: action.payload, loading: false };
  
    case "SET_LOADING_ACCOUNT":
      return { ...state, loading: action.payload };
     
    default:
      return state;
  }
};

// Async Action Creator for Cart Items
const fetchCartItems = (lastItemAdded) => async (dispatch) => {
  dispatch({ type: "SET_CART_LOADING", payload: true });
  try {
    const response = await axiosServer.get(buildLink("getProductsCart"));
    dispatch({ type: "SET_CART_ITEMS", payload: response.data.items });
    dispatch({ type: "SET_CART_COUNT", payload: response.data.total_count });
    dispatch({ type: "SET_TOTAL_PRICE", payload: response.data.total_price });
    if(lastItemAdded){
      dispatch({ type: "SET_LAST_ITEM_ADDED", payload: lastItemAdded });
      dispatch({ type: "SET_OPEN_CART_SIDE", payload: true });
      setTimeout(() => {
        dispatch({ type: "SET_LAST_ITEM_ADDED", payload: 0 });
      }, 5000);

    }
  } catch (error) {
    console.error("Failed to fetch cart items:", error);
  } finally {
    dispatch({ type: "SET_CART_LOADING", payload: false });
  }
};

// User Reducer
const userReducer = (state = { name: "Ghina" }, action) => {
  switch (action.type) {
    case "CHANGE_NAME":
      return { ...state, name: action.payload };
    default:
      return state;
  }
};

// Root Reducer
const rootReducer = combineReducers({
  categories: categoriesReducer,
  cart: cartReducer,
  user: userReducer,
  accountReducer
});

// Store with Thunk Middleware
const storeCategory = createStore(rootReducer, applyMiddleware(thunk));

export { storeCategory, fetchCartItems };
