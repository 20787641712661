import React, { useEffect, useState } from "react";
import productImage from "../assets/images/368~1.jpg";
import { FaPlus, FaSpinner } from "react-icons/fa";
import { axiosServer, buildLink } from "../lib/server";
import { useDispatch } from "react-redux";
import { fetchCartItems } from "../reducer/storeReducer";

const Product = ({ product }) => {
  const dispatch = useDispatch(); // Get the dispatch function
  const [loading, setLoading] = useState(false);

  const addToCart = () => {
    setLoading(true);

    axiosServer
      .post(buildLink("addToCart"), { product_id: product.id })
      .then((response) => {

        dispatch(fetchCartItems(product.id));
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col gap-3 rounded-xl px-2 py-2 border bg-white">
      <div className=" w-full  relative overflow-hidden h-[70%]">
        {product.out_of_stock && <div className=" w-full h-full absolute inset-0   bg-white bg-opacity-60 flex justify-center"><h2 className=" text-red-500  font-bold text-center">Out of stock</h2></div>}
        <img
          src={productImage}
          className="w-full h-full object-cover"
          alt="productImage"
        />
      </div>
      <div className=" w-full h-12">
        <h2 className="text-sm font-bold">{product.description}</h2>
      </div>
      <div className=" flex flex-row justify-between  ">
        <h2>${product.cost}</h2>
       { !product.out_of_stock && <button className=" rounded-full p-2 bg-[#8BC542] " onClick={addToCart}>
          {loading ? (
            <div className="w-4 h-4 rounded-full bg-white animate-ping "> </div>
          ) : (
            <FaPlus className=" text-white " />
          )}
        </button>}
      </div>
    </div>
  );
};

export default Product;
