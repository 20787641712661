import axios from "axios";
import Cookie from "js-cookie";

function buildLink(endPoint) {
  return "https://api.interfaceedupro.com/public/" + endPoint;
}


const axiosServer = axios.create({});

const getTokenFromCookie = () => {
  return Cookie.get("api-token");
};

const saveTokenToCookie = (token) => {
  Cookie.set("api-token", token, { expires: 7 }); // Set token with an expiration of 7 days
};

// Function to fetch a new token if none exists
const fetchNewToken = async () => {
  try {
    const response = await axios.get(buildLink('getToken')); 
    const newToken = response.data.token;
    saveTokenToCookie(newToken);
    return newToken;
  } catch (error) {
    console.error("Error fetching new token:", error);
    throw error;
  }
};

// Add an interceptor to inject the token into requests
axiosServer.interceptors.request.use(async (config) => {
  let token = getTokenFromCookie();

  if (!token) {
    // Fetch a new token if it does not exist
    // token = await fetchNewToken();
  }

  config.headers.Authorization = `Bearer ${token}`;
  return config;
}, (error) => {
  return Promise.reject(error);
});

var isRefreshing=false;

axiosServer.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error?.response?.status === 401 &&
      !error.config._retry &&
      !isRefreshing
    ) {
      error.config._retry = true;
      isRefreshing = true;

     

      try {
        // Request a new token
        const response = await axios.get(buildLink("getToken"));
        const newToken = response.data.token;

        // Update the token in your authentication state or cookie
        Cookie.set("api-token", response.data.token, { expires: 15 });

        // Trigger the onRefreshed callback to notify other requests
        // onRefreshed(newToken);
        // Refresh the page
        // if (typeof window !== "undefined") {
          window.location.reload();
        // }
        // Retry the original request with the new token
        error.config.headers.Authorization = `Bearer ${newToken}`;
        return axiosServer(error.config);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);


export { buildLink ,axiosServer,saveTokenToCookie};
