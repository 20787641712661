import React, { useEffect } from "react";
import productImage from "../assets/images/368~1.jpg";
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { FiShoppingCart } from "react-icons/fi";
import { axiosServer, buildLink } from "../lib/server";
import { fetchCartItems } from "../reducer/storeReducer";
import { useNavigate } from "react-router-dom";
import { BsCartPlus } from "react-icons/bs";

const CardList = () => {
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state.cart);
  const history = useNavigate()
  useEffect(()=>{
console.log(cartState.lastItemAdded)
  },[cartState.lastItemAdded]);



  const EditQty = (product_id,type) => {
    // setLoading(true);
    dispatch({type:"SET_CART_LOADING",payload:true});
    axiosServer
      .post(buildLink("EditQty"), { product_id: product_id ,typeEdit:type})
      .then((response) => {
        dispatch(fetchCartItems());
      
      });
  };




  return (
    <div className="  overflow-y-auto  h-[90vh] max-md:h-screen top-20 max-md:top-0  flex flex-col  gap-2  py-4 px-2 border-r border-gray-300 border-opacity-30">
     <div className=" flex flex-row justify-between px-2">
      <h2 className=" text-xl gap-2 flex justify-between font-bold my-2">Your Cart<span>{`(${cartState.cartCount})`}</span> <button className="text-xs my-auto py-2 font-light text-red-600 bg-white  flex justify-center gap-2 rounded-md  px-2"><span className=" my-auto">Clear</span><FaTrash className=" my-auto"/></button></h2>
      <button   onClick={()=> dispatch({type:"SET_OPEN_CART_SIDE" , payload:false})}className=" md:hidden" ><MdClose className=" text-2xl my-auto"/></button>
      </div>
      <div className=" h-full flex-col  md:overflow-y-auto gap-2 flex w-full ">
     
     


{ cartState.cartItems && cartState.cartItems.length >0? cartState.cartItems.map((item)=>{
return   <div className={`flex  ${item.out_of_stock && " bg-[#ff5a5a] bg-opacity-50"} rounded-md ${cartState.lastItemAdded == item.id?"bg-[#8BC542] bg-opacity-50":"bg-white "}    flex-row gap-2 border-white border-2 transition-all duration-500 ease-out p-4`}>
<div className=" flex w-full flex-col">
  <div className="flex  justify-between w-full flex-row">
    <div className=" flex flex-col gap-1">
      <h2 className="text-gray-500">SCRUNCH</h2>
      <h2 className="font-semibold">
        {item.description}{" "}
      </h2>
    </div>
    <div className="w-16">
      <img src={productImage} alt="productImage"></img>
    </div>
  </div>
  <h2 className="text-gray-400 text-sm">${item.cost}</h2>
  <div className="flex flex-row justify-between">
    { !item.out_of_stock &&  <div className="flex flex-row gap-4">
      <button onClick={()=>EditQty(item.id,'minus')} className="rounded-sm  w-6  border-0 hover:shadow-lg text-red-500">
        -
      </button>
      <div className=" text-center input-number  my-auto    outline-none bg-transparent ">
        {item.cart_quantity}
      </div>
      <button onClick={()=>EditQty(item.id,'add')} className="rounded-sm   border-0 hover:shadow-lg text-red-500">
        +
      </button>
      <button className="rounded-sm w-6 border-0 hover:shadow-lg opacity-30 text-sm ">
        <FaTrash />
      </button>
    </div>}
    <div className=" font-bold text-xs my-auto">
   ${item.total_cost}
  </div>
  </div>
  
</div>
</div>
}):<></>}


{!cartState.loading &&  cartState.cartItems.length == 0 && <div className=" w-full h-full flex justify-center"><div className=" h-fit flex flex-col gap-3 text-center my-auto"><BsCartPlus className=" text-5xl mx-auto" /><span className=" text-2xl text-gray-400">Empty Cart</span></div></div>}

{
  cartState.loading && <div className=" w-full h-full  absolute inset-0 bg-white bg-opacity-35  flex justify-center">
     <FiShoppingCart className=" my-auto text-3xl text-white p-2 rounded-md bg-[#8BC542] animate-ping opacity-65"/>
  </div>
}


    
  
      </div>

      <div className=" w-full flex justify-center text-center">
        <button onClick={()=>{ 
          dispatch({type:"SET_OPEN_CART_SIDE",payload:false})
           history('checkout')}} className=" px-3 flex flex-row justify-between w-full h-full bg-[#8BC542] rounded-md text-white py-3 ">
          <span>checkout</span>
          <span>${cartState.total_price}</span>
          </button>
      </div>
      
    </div>
  );
};

export default CardList;
