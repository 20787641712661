import React, { useEffect, useState } from "react";
import image from "../assets/images/1.png";
import { FaPlus } from "react-icons/fa";
import { axiosServer, buildLink } from "../lib/server";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Productcardloading from "../components/productcardloading";
import CategoryCardLoading from "../components/categoryCardLoading";
import Product from "../components/product";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import brand1 from '../assets/images/brand1.png';
import logo from '../assets/images/old_house.png'
import brand2 from '../assets/images/brand2.jpeg';

const HomePage = () => {
  const [items, setItems] = useState(null);
  const categories = useSelector((state) => state.categories);
  const dispatch = useDispatch(); // Get the dispatch function

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axiosServer.get(buildLink("getItems")).then((response) => {
      setItems(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <div className=" w-full py-5  flex flex-col h-full gap-3 max-md:w-full max-md:mt-0 max-md:py-0">
      <div className=" w-full h-full">
        <img className="rounded-md" src={image} alt="imgss" />
      </div>

      {/* <div className="w-full">
        <Swiper
        slidesPerView={4}
        spaceBetween={10}
        centeredSlides={false}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}

        className="mySwiper">
          <SwiperSlide className="rounded-2xl  "><img  src={logo}/></SwiperSlide>
          <SwiperSlide className="rounded-2xl"><img  src={brand2}/></SwiperSlide>
          <SwiperSlide className="rounded-2xl"><img  src={brand1}/></SwiperSlide>
          <SwiperSlide className="rounded-2xl"><img  src={logo}/></SwiperSlide>

          <SwiperSlide className="rounded-2xl"><img  src={brand2}/></SwiperSlide>

          <SwiperSlide className="rounded-2xl"><img  src={brand1}/></SwiperSlide>

          <SwiperSlide className="rounded-2xl"><img  src={logo}/></SwiperSlide>

          <SwiperSlide className="rounded-2xl"><img  src={brand1}/></SwiperSlide>

        </Swiper>
      </div> */}

      <div className=" w-full h-full flex flex-col gap-3 ">
        <div className=" grid   py-4 gap-1 shadow-md  border border-gray-300 border-opacity-25 px-2 md:hidden  max-md:grid-cols-2 ">
          {categories.categories && !categories.loading ? (
            categories.categories.map((category) => (
              <Link
                to={`category/${category.id}`}
                className="flex flex-col gap-3 rounded-xl px-2 py-2 border bg-white "
              >
                <div className=" w-full  relative overflow-hidden h-[80%]">
                  <img
                    src="https://liveorganic.weevi.com/static/content/uploads/ekomcategories/SNACKS.jpg"
                    className="w-full h-full object-cover"
                    alt="productImage"
                  />
                </div>
                <div className=" w-full h-[20%]">
                  <h2 className="text-sm font-bold text-center">
                    {category.description}
                  </h2>
                </div>
              </Link>
            ))
          ) : (
            <>
              <CategoryCardLoading />
              <CategoryCardLoading />
              <CategoryCardLoading />
              <CategoryCardLoading />
              <CategoryCardLoading />
              <CategoryCardLoading />
              <CategoryCardLoading />
              <CategoryCardLoading />
            </>
          )}
        </div>
      </div>

      <div className=" w-full h-full flex flex-col gap-3 ">
        <div className=" w-full flex flex-row justify-between max-md:px-5">
          <h2 className=" text-3xl font-medium text-amber-700 max-md:text-2xl ">
            LATEST ITEMS
          </h2>
          <button>View More</button>
        </div>

        <div className=" grid py-2  gap-1   border-opacity-25 px-2 grid-cols-5 max-md:grid-cols-2">
          {!loading && items ? (
            items.map((item) => <Product product={item} />)
          ) : (
            <>
              <Productcardloading />
              <Productcardloading />
              <Productcardloading />
              <Productcardloading />
              <Productcardloading />
              <Productcardloading />
              <Productcardloading />
              <Productcardloading />
              <Productcardloading />
              <Productcardloading />
              <Productcardloading />
              <Productcardloading />
            </>
          )}
        </div>

        {/* <div className=" grid  gap-1 shadow-md  border border-gray-300 border-opacity-25 px-2 grid-cols-5 max-md:grid-cols-2">
          {items &&
            items.map((item) => (
              <div className="flex flex-col gap-3 rounded-xl px-2 py-2 border bg-white">
                <div className=" w-full  relative overflow-hidden h-[70%]">
                  <img
                    src={productImage}
                    className="w-full h-full object-cover"
                    alt="productImage"
                  />
                </div>
                <div className=" w-full h-12">
                  <h2 className="text-sm font-bold">{item.description}</h2>
                </div>
                <div className=" flex flex-row justify-between  ">
                  <h2>${item.cost}</h2>
                  <button className=" rounded-full p-2 bg-[#8BC542] ">
                    <FaPlus className=" text-white" />
                  </button>
                </div>
              </div>
            ))}
        </div> */}
      </div>
    </div>
  );
};

export default HomePage;
