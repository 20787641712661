import React, { useEffect, useRef, useState } from "react";
import { FaAngleRight, FaShoppingBasket, FaTruckPickup } from "react-icons/fa";
import { useSelector } from "react-redux";
import productImage from "../assets/images/368~1.jpg";
import { MdTakeoutDining } from "react-icons/md";
import { BsBasket2 } from "react-icons/bs";
import { axiosServer, buildLink } from "../lib/server";
import { Link } from "react-router-dom";
import Select from "react-select";
import Loader from "../components/loader";
// import { Select } from "@material-tailwind/react";
const Checkout = () => {

  const cartState = useSelector((state) => state.cart);
  const [extendProductCart, setextendProductCart] = useState(true);
  const [selectedOrderType, setSelectedOrderType] = useState("pickup");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [zones, setzones] = useState([]);
  const [selectedDelivery, setSelectedelivery] = useState(null);
  const [checkoutData, setCheckoutData] = useState(null);

  const initialCheckout = async () => {
    setError(null)
    try {
      setLoading(true);
      const obj = {
        order_type: selectedOrderType,
        delivery_address: selectedDelivery,
        products: cartState.cartItems.map((item) => item.id),
      };
      const response = await axiosServer.post(
        buildLink("initial_checkout"),
        obj
      );
      if (response.data.success) {
        setCheckoutData(response.data);
      }else{
        setError(response.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log("Checkout error:", err);
    }
  };

  const getZones = async () => {
    setLoading(true);
    try {
      const res = await axiosServer.get(buildLink("getZone"));
      const transformedData = res.data.map((item) => ({
        label: item.Description,
        value: item.IdRegion,
      }));
      setzones(transformedData);
    } catch (err) {
      console.log("Error fetching zones:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getZones();
  }, []);

  useEffect(() => {
    if (zones.length > 0) {
      initialCheckout();
    }
  }, [zones, selectedDelivery, selectedOrderType]);
  
  const checkoutOrder = () => {
    const obj = {
      order_type: selectedOrderType,
      delivery_address: selectedDelivery,
      products: cartState.cartItems.map((item) => item.id),
    };
    axiosServer.post(buildLink("checkout"),obj).then((response) => {
     
    });
  };

  return (
    <div className=" flex flex-col gap-3 mt-8 h-full min-h-screen ">
      <h2 className=" text-2xl text-[#8BC542] py-1 font-bold flex flex-row  px-2 gap-5">
        <span>Checkout</span> <FaShoppingBasket className=" my-auto" />
        {
          error && <span className="text-red-500 text-md font-thin">{error}</span>
        }
      </h2>

      <div className=" flex p-3  bg-gray-50  gap-3 w-full">

        <label
          className={` py-5 ${
            selectedOrderType == "delivery" ? "bg-[#8BC542] " : " bg-white"
          } px-2 bg-opacity-30 w-full border border-opacity-30  flex flex-row  justify-start gap-3`}
        >
          <input
            onChange={() => setSelectedOrderType("delivery")}
            id="delivery"
            checked={selectedOrderType == "delivery"}
            type="radio"
          ></input>
          <div className=" flex flex-row justify-start gap-2 ">
            <span>Delivery</span>{" "}
            <FaTruckPickup className=" my-auto text-2xl" />
          </div>
        </label>

        <label
          className={` py-5 ${
            selectedOrderType == "pickup" ? "bg-[#8BC542] " : " bg-white"
          } px-2 bg-opacity-30 w-full border border-opacity-30  flex flex-row  justify-start gap-3`}
        >
          <input
            onChange={() => setSelectedOrderType("pickup")}
            id="pickup"
            checked={selectedOrderType == "pickup"}
            type="radio"
          ></input>
          <div className=" flex flex-row justify-start gap-2 ">
            <span>Pickup</span> <BsBasket2 className=" my-auto text-2xl" />
          </div>
        </label>

      </div>

      {selectedOrderType == "delivery" && (
        <div className=" flex p-3  bg-gray-50 flex-col   gap-3 w-full">
          <h2 className=" font-bold ">Choose your address</h2>
          <div className=" w-full py-3">
            <Select
              placeholder="Select Address"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: "7px",
                  borderColor: state.isFocused ? "grey" : "grey",
                  outline: "red",
                  padding: "8px 5px",
                }),
              }}
              onChange={(e) => setSelectedelivery(e.value)}
              options={zones}
            />
          </div>
        </div>
      )}

      <div className=" flex p-3  flex-col justify-start bg-gray-50  gap-3 w-full">
        <button
          onClick={() => setextendProductCart(!extendProductCart)}
          className=" flex flex-row justify-between w-full "
        >
          <span className=" font-bold">
            Order Product
            {checkoutData && (
              <span>
                {" "}
                ({checkoutData && checkoutData.product_cart.total_count})
              </span>
            )}
          </span>
          <FaAngleRight
            className={` ${
              extendProductCart ? " rotate-90" : "rotate-0"
            } transition-all my-auto text-lg`}
          />
        </button>
        <div
          className={`${
            extendProductCart ? " h-[17rem]" : "h-0"
          }  relative overflow-auto transition-all flex flex-col gap-2  `}
        >
          {checkoutData &&
            checkoutData.product_cart &&
            checkoutData.product_cart.items &&
            checkoutData.product_cart.items.length &&
            checkoutData.product_cart.items.map((item) => {
              return (
                <div
                  className={`flex shadow-sm  rounded-md bg-gray-50 "
                  }    flex-row gap-2 border-white border-2 transition-all duration-500 ease-out p-4`}
                >
                  <div className=" flex w-full  flex-row">
                    <div className="flex  gap-2 justify-start w-full flex-row">
                      <div className="w-16">
                        <img src={productImage} alt="productImage"></img>
                      </div>
                      <div className=" flex flex-col justify-between gap-1">
                        <h2 className=" text-gray-600">{item.description} </h2>
                        <div className=" text-start flex flex-row gap-2 input-number     outline-none bg-transparent ">
                          <span className=" text-gray-500 my-auto text-sm">
                            X
                          </span>
                          <h2 className="   font-bold">{item.cart_quantity}</h2>
                        </div>
                      </div>
                    </div>
                    {/* <h2 className="text-gray-400 text-sm">${item.cost}</h2> */}
                    <div className="flex flex-col justify-between">
                      <div className="flex flex-col gap-4"></div>
                      <div className=" text-red-600  font-bold text-xs my-auto">
                        ${item.total_cost}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="  flex p-3 flex-col  bg-white  gap-3 w-full">
        <div className="flex flex-col gap-2 justify-between">
          {selectedOrderType == "delivery" && selectedDelivery && (
            <div className=" flex flex-row justify-between w-full">
              <div className=" flex flex-col gap-1 shadow-sm ">
                <h2>Delivery</h2>
                <h2 className="text-sm text-gray-400 ">
                  {checkoutData.delivery}
                </h2>
              </div>
              {checkoutData && (
                <h2>${checkoutData.product_cart.cost_delivery}</h2>
              )}
            </div>
          )}
          <div className=" flex flex-row justify-between w-full">
            <h2>Sub-Total</h2>
            {checkoutData && <h2>${checkoutData.product_cart.sub_total}</h2>}
          </div>

          <div className=" flex flex-row justify-between w-full">
            <h2>Total</h2>
            {checkoutData && (
              <h2 className="text-red-500">
                ${checkoutData.product_cart.total_price}
              </h2>
            )}
          </div>
        </div>

        <div className="flex flex-row max-md:flex-col  gap-2 w-full">
          <Link
            to={"/"}
            className=" bg-transparent hover:bg-[#8BC542] w-full hover:text-white text-[#8BC542] border-[#8BC542] border-2 rounded-md   text-center py-3 "
          >
            Continue Shopping
          </Link>
          <button disabled={error}
            className=" bg-[#8BC542] rounded-md  w-full text-white text-center py-3 "
            onClick={checkoutOrder} 
          >
            Confirm
          </button>
        </div>
      </div>

      {loading && (
        <div className=" fixed  flex justify-center  w-full h-full z-50 inset-0 bg-black bg-opacity-70 ">
          <div className=" my-auto mx-auto w-fit h-fit ">
            <Loader />
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;
